import http from "@/common/http"; //axios实例化后引入取名http

// 新增
const addOrder = async (data)=>{
    let list = await http.post(`/order/app.add/${data.id}`,{ 
        ...data
    })
    return list
}
// 订单列表
const listOrder = async (data)=>{
    let list = await http.post(`/order/app.list`,{ 
        ...data
    }) 
    return list
}
// 删除订单
const delOrder = async (data)=>{
    let list = await http.delete(`/order/app.del/${data.id}`,{ 
        ...data
    })
    return list
}

// 取消订单
const cancelOrder = async (data) => {
    let list = await http.get(`order/app.cancel/` + data.id,{ 
        ...data
    })
    return list 
}

export {  
    addOrder,
    listOrder,
    delOrder, 
    cancelOrder,
}