import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    role_leave:''
  },
  getters: {
  },
  mutations: {
    // 更新store中的state
    UpDateState(state,data){
        for(let k in data){
          if(data[k]||data[k]==0){
            state[k] = data[k]
          }
        } 
        localStorage.setItem('jyc-web-state',JSON.stringify(state))
    },
    // 清空store中的state
    resetState(state){
      for(let k in state){
        state[k] = ""
      }
    } 
  },
  actions: {
  },
  modules: {
  }
})
