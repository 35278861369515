<template>
  <div>
    <header class="home-nav">
      <div class="nav-left">
        <!-- <div class="ml10 epc-menu">电子目录</div> -->
        <div class="ml10" v-if="!$store.state.token" @click="loginOut">登录</div>
        <div class="ml10" v-if="!$store.state.token" style="color: #d5d5d5">|</div>
          <div style="cursor: pointer;" v-if="$route.path!='/'&&$route.path!='/home'" @click="goHome">
            <img src="../assets/index/indexicon.svg" style="width: 18px;height: 18px;vertical-align: sub;" alt="">
            景阳诚首页</div>
      </div>
      <div class="nav-right">
        <!-- <div class="ml10 color999">
          剩余次数：<span style="color: red">空</span>
        </div> -->
        
        <!-- <div class="ml10" style="color: #d5d5d5">|</div> -->
        <div class="ml10">
          <el-dropdown @command="commandEven">
            <span class="el-dropdown-link">
              {{ $store.state.user }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item style="background-color: #fff" command="person"
                >个人中心</el-dropdown-item
              > -->
              <el-dropdown-item style="background-color: #fff" command="myorder"
                >我的订单</el-dropdown-item
              >
              <el-dropdown-item
                style="background-color: #fff"
                command="loginOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="ml10" style="color: #d5d5d5">|</div>
        <div class="ml10" @click="goOrder">我的订单</div>
        <div class="ml10" style="color: #d5d5d5">|</div>
        <div class="ml10 epc-menu" @click="goEpc">电子目录</div>
      </div>
    </header>
    <!-- 头部搜索 -->
    <div v-if="$route.meta.isSearch" class="elecatalogTitle">
      <div class="elecatalogTitleMain">
        <div class="ybj-img" @click="goHome">
          <img src="@/assets/index/jyc-nav.jpg" alt="" />
        </div>
        <div class="jyc-text" @click="goHome">
          <div class="text-top">景阳诚采购平台</div>
          <div class="text-bottom">{{ strcon.toLocaleUpperCase() }}</div>
        </div> 
        <div class="elecatalog-tool" v-if="$route.meta.isSearch">
          <div style="display: flex;align-items: center;margin-top: 20px;overflow: hidden;">
            <div class="search"> 
              <el-autocomplete
                class="inline-input"
                v-model="ProductCode"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                value-key="data"
                @select="handleSelect"
                @focus="getHistory()"
              ></el-autocomplete> 
              <el-button class="seach-btn" @click="search"> 搜索 </el-button> 
            </div> 
            <div class="shop-btn" @click="goShopCar">
              <img src="../assets/index/shopcarlogo.svg" alt="" style="width: 20px;height: 20px;vertical-align: sub;">
              我的购物车</div>
          </div>
          <div class="nav-home" v-if="$route.meta.isNav">
            <div class="child-home wid50" @click='goHome' :class="{'active':$route.path=='/home'}">首页</div>
            <div class="child-home" @click='goFind' :class="{'active':$route.path=='/find'}">找配件</div>
            <div class="child-home" @click='goIntegral' :class="{'active':$route.path=='/integral'}">积分商城</div>
          </div>
        </div>
        <div class="elecatalog-tool2" v-else>
          <div class="tool-three" style="color: #0F3983;" @click="goOrder">
            <img
              style="width: 16px; height: 16px; vertical-align: middle;margin-right: 5px;"
              src="@/assets/index/person.svg"
              alt=""
            />个人中心
          </div> 
          <div class="shop-btn" @click="goShopCar">
              <img src="../assets/index/shopcarlogo.svg" alt="" style="width: 20px;height: 20px;vertical-align: sub;">
              我的购物车</div>
          
        </div>
      </div>
    </div>

    <div v-else class="elecatalogTitle" style="background-color: #0F3983;">
      <div class="elecatalogTitleMain">
        <div class="ybj-img" @click="goHome">
          <img src="@/assets/index/jyc-nav.jpg" alt="" />
        </div>
        <div class="jyc-text" @click="goHome">
          <div class="text-top" style="color: #fff;">景阳诚采购平台</div>
          <div class="text-bottom" style="color: #fff;">{{ strcon.toLocaleUpperCase() }}</div>
        </div>
        <div style="font-size: 14px;text-align: center;margin: 0 20px;">
          <div style="color: #fff;">我的景阳诚</div>
          <div @click="goHome" style="font-size: 12px;border: 1px solid #fff;border-radius: 20px;cursor: pointer;padding: 5px;color: #fff;box-sizing: border-box;margin: 5px 0;"
          >返回景阳诚首页</div>
        </div> 
        <div class="elecatalog-tool2"> 
          <div class="shop-btn" @click="goShopCar">
              <img src="../assets/index/shopcarlogo.svg" alt="" style="width: 20px;height: 20px;vertical-align: sub;">
              我的购物车</div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "headerNav",
  data() {
    return {
      strcon: "Jingyangcheng caigou pingtai",
      ProductCode: "", 
      restaurants:[],//搜索历史 
    };
  },
  methods: { 
    // 获取历史记录
    async getHistory(){
      console.log("this.$route",this.$route)
      let resHis  = await this.$api.getSearchHis({ 
          });
      if(resHis.code==200){
        this.restaurants = resHis.data
      }
      // this.restaurants = this.loadAll();
    },
    querySearch(queryString, cb) {
      console.log("queryString",queryString)
        var restaurants = this.restaurants; 
        cb(restaurants);
      }, 
    handleSelect(item) {
        this.ProductCode = item.data
    }, 
    // 去电子目录
    goEpc(){
      router.push('/epc')
    },
    // 搜索页
    search(){
      this.ProductCode = this.ProductCode.replace(/\s/g, "");
      if(this.ProductCode==''){
        return this.$warMsg("请输入搜索内容")
      }
      router.push(`/find?key=${this.ProductCode}`)
      // setTimeout(() => {
      //   location.reload()
      // },50)
    },   
    // 去积分商城
    goIntegral(){ 
      router.push('/integral')
    },
    // 去找配件
    goFind(){ 
      router.push("/find")
    },
    // 去首页
    goHome() { 
      router.push("/home");
    },
    // 去购物车
    goShopCar() {
      if(this.$store.state.role==3){
        router.push("/shopcar");
      }else{
        this.$warMsg("暂无权限")
      }
    },
    // 退出登录
    loginOut() {
      // 清理本地缓存
      localStorage.removeItem("jyc-web-state");
      // 清空store中的state
      this.$store.commit("resetState");
      // 退到登录页
      router.push("/login");
    },
    // 跳转到我的订单
    goOrder(){
      router.push("/order")
    },
    // 点击下拉菜单
    commandEven(option) {
      if (option == "loginOut") {
        this.loginOut();
      }
      if (option == "myorder") {
        this.goOrder()
      }
    },
  },
  async mounted() { 
    
  },
};
</script>

<style lang="scss" scoped>
.home-nav {
  height: 40px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  .nav-right,
  .nav-left {
    display: flex;
    color: #666666;
    font-size: 14px;
    .ml10 {
      margin: 0 10px;
      cursor: pointer;
    }
    .epc-menu {
      color: red;
    }
    .color999 {
      color: #999999;
    }
    .shop-car {
      cursor: pointer;
    }
  }
}
.elecatalogTitle {
  width: 100%;
  height: 110px;
  background: #fff;
  .elecatalogTitleMain {
    width: 1200px;
    height: 110px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .ybj-img {
      width: 70px;
      height: 70px;
      text-align: center;
      // line-height: 160px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .jyc-text {
      color: #0f3983;
      margin-left: 9px;
      cursor: pointer;
      .text-top {
        font-size: 24px;
        font-family: FZSUXSYBJW--GB1-0;
        font-weight: bold;
        letter-spacing: 2px;
      }
      .text-bottom {
        font-size: 10px;
        font-family: SourceHanSansCN-Regular;
        // letter-spacing: -1px;
      }
    }
    .elecatalog-tool2 {
      margin-left: auto;
      display: flex;
      align-items: center;
      .shop-btn{
        width: 128px;
        height: 42px;
        background-color: #eee;
        border-radius: 4px; 
        color: #0F3983;
        text-align: center;
        line-height: 42px;
        margin-left: 120px;
        margin-top: 2px;
      }
      .tool-one {
        width: 312px;
        height: 30px;
        background: #cfd6e8;
        padding: 5px;
        box-sizing: border-box;
        color: #333;
        font-size: 14px;
      }
      .tool-two {
        width: 100px;
        height: 28px;
        border: 1px solid #666666;
        font-size: 14px;
        color: #666;
        text-align: center;
        line-height: 28px;
      }
      .tool-three{
        width: 100px;
        height: 25px; 
        font-size: 16px;
        color: #666;
        text-align: center;
        line-height: 25px;
      }
    }
    .elecatalog-tool {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between; 
      .search {
          display: flex;
          align-items: center;
          // 预购单要向后来点
          // margin-right: -41px;
          // margin-right: 98px;
          ::v-deep .el-input__inner { 
            height: 37px; 
          } 
          .inline-input {
            width: 494px;
            height: 34px;
            border-radius: 0;
            .el-input__inner {
              border-radius: 0;
              height: 34px;
            }
          }
          .seach-btn {
            width: 100px;
            background: #0f3983;
            color: #fff;
            border-radius: 0;
            height: 41px;
            position: relative;
            left: -2px;
            top: 3px;
          }
          .search-history {
            display: flex;
            .his-item {
              padding: 10px 10px 0 0;
              font-size: 12px;
              color: #999;
              cursor: pointer;
            }
            .his-item:hover {
              color: #0f3983
            }
          }
        }

      .nav-home{
        display: flex;
        align-items: center;
        .child-home{
          width: 70px;
          height: 34px;
          // color: #0f3983;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          margin: 0 10px;
          cursor: pointer;
        }
        .wid50{
          width: 50px;
        }
        .active{
          // background-color: #0f3983;
          color: #0f3983;
        }
      }
      .search-btn {
        display: flex;
        // margin-top: 20px;
        div {
          margin: 0 10px;
          color: rgb(157, 177, 226);
          font-size: 14px;
          line-height: 30px;
        }
        .active {
          color: blue;
        }
      }
      .sear-input-box {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 800px;
        flex: 1;
        border: 1px solid #ccc;
        .img {
          width: 30px;
          height: 30px;
          margin: 6px 0 0 12px;
        }
        .search-button {
          width: 70px;
          height: 44px;
          background: rgb(52, 78, 139);
          color: #fff;
          text-align: center;
          line-height: 44px;
        }
      }
      .vin-code-input {
        position: absolute;
        left: 53px;
        top: 0;
        z-index: 10;
        width: 624px;
        // height: 2.121212rem /* 70/33 */;
        .el-input__inner {
          font-weight: 700;
          border-right: none;
          font-size: 16px;
          color: #3158c6;
          padding-right: 10px;
          height: 100%;

          &::placeholder {
            font-size: 12px;
          }
        }
        ::v-deep .el-input__inner {
          border: 1px solid #fff;
        }
      }
      .vin-his-box {
        position: absolute;
        left: 57px;
        top: 0;
        z-index: 8;
        width: 100%;

        height: 2.121212rem /* 70/33 */;
        ::v-deep .el-input {
          height: 2.121212rem /* 70/33 */;
          width: 673px;
          height: 40px;
        }
        ::v-deep .el-input__inner {
          border-right: none;
          height: 100%;
          border: 1px solid #fff;
        }
      }
      .shop-btn{
        width: 128px;
        height: 42px;
        background-color: #eee;
        border-radius: 4px; 
        color: #0F3983;
        text-align: center;
        line-height: 42px;
        margin-left: 120px;
        margin-top: 2px;
      }
      .search-input {
        display: flex;
        border: 1px solid #ccc;
        width: 600px;
        padding-left: 10px;
        position: relative;
        .inline-input {
          ::v-deep .el-input__inner {
            // border-right: none;
            // height: 100%;
            height: 34px;
            width: 709px;
            border-radius: 0;
            margin-right: -5px;
            border-color: #fff;
          }
          ::v-deep .el-input__inner:focus {
            border-color: #fff;
          }
          ::v-deep .el-input__inner:hover {
            border-color: #fff;
          }
        }
        .img1 {
          width: 14px;
          height: 14px;
          margin: 16px 24px 0 0;
        }
        .img2 {
          width: 8px;
          height: 14px;
          margin: 16px 11px 0 0;
        }
        .img3 {
          width: 15px;
          height: 15px;
          margin: 16px 11px 0 0;
        }
        .vin-his-box {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 8;

          height: 2.121212rem /* 70/33 */;
          .el-input {
            height: 2.121212rem /* 70/33 */;
          }
          .el-input__inner {
            // border-right: none;
            // height: 100%;
            height: 44px;
            width: 709px;
            border-radius: 0;
            margin-right: -5px;
          }
        }
        .sear-his-list {
          border: 1px solid #ccc;
          position: absolute;
          top: 45px;
          left: -1px;
          width: 810px;
          background: #fff;
          z-index: 10;
          .list {
            .his-item {
              height: 35px;
              line-height: 35px;
              font-size: 18px;
              padding-left: 10px;
              box-sizing: border-box;
              &:hover {
                background: #eee;
              }
            }
          }
        }
        .search-code {
          flex: 1;
          height: 34px;
          vertical-align: middle;
          padding: 10px;
          box-sizing: border-box;
          outline: none;
          border: none;
        }
        ::v-deep .el-input__inner {
          height: 44px;
          width: 800px;
          border-radius: 0;
          margin-right: -5px;
        }
        .search-button {
          width: 77px;
          height: 34px;
          background: rgb(52, 78, 139);
          color: #fff;
          text-align: center;
          line-height: 34px;
        }
        .img {
          height: 20px;
          margin-top: 13px;
        }
      }
    }
  }
}
</style>