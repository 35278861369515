import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/index'
import store from '@/store'

Vue.use(VueRouter)

// isSearch 是否显示导航栏中的搜索框
// isNav 首先是导航栏搜索框下面的页面导航

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      isSearch:true
    },
    redirect:{
      name:"homemain"
    },
    children:[
      {
        path:'/home',
        name:'homemain',
        meta:{
          isSearch:true,
          isNav:true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/home')
      },
      {
        path: '/shopcar',
        name: 'shopcar', 
        meta:{
          isSearch:true,
          isNav:false,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/shopcar')
      },
      // 订单页
      {
        path:'/order',
        name:'orderpage',
        meta:{
          isSearch:false
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/order')
      },
      // 订单详情页
      {
        path:'/orderinfo/:id',
        name:'orderInfopage',
        meta:{
          isSearch:false
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/orderInfo')
      },
      // 找配件
      {
        path:'/find',
        name:'findpage',
        meta:{
          isSearch:true,
          isNav:true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/find')
      },
      // 积分商城
      {
        path:'/integral',
        name:'integralpage',
        meta:{
          isSearch:true,
          isNav:true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/integral')
      },
      // 商品详情页
      {
        path:'/goodsinfo',
        name:'goodsinfolpage',
        meta:{
          isSearch:true,
          isNav:true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/goodsinfo')
      },
      // 电子目录
      {
        path:'/epc',
        name:'epc',
        meta:{
          isSearch:true,
          isNav:false,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/epc')
      },
    ]
  },  
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/login/index.vue')
  },
   
]

// 解决路径冗余 （重复点击同一个路径时不会报错）
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
} 

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('前置路由守卫', to, from,Vue.prototype.$api,store); 
  next()
}) 

export default router
