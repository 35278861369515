import http from "@/common/http"

// 新增
const addAdress = async (data) => {
    let list = await http.post(`/address/app.add`,{
        ...data
    })
    return list
}

// 获取列表
const listAdress = async (data) => {
    let list = await http.get(`/address/app.list`,{
        ...data
    })
    return list
}

// 删除
const delAdress = async (data) => {
    let list = await http.delete(`/address/app.del/${data.id}`,{
        ...data
    })
    return list
}

// 设置为默认地址
const setMorenAdress = async (data)=>{
    let list = await http.post(`/address/app.default/${data.id}`,{ 
        ...data
    })
    return list
}

// 修改地址
const modifyAdress = async (data)=>{
    let list = await http.post(`/address/app.update/${data.id}`,{ 
        ...data
    })
    return list
}
export {
    addAdress,
    listAdress,
    delAdress,
    setMorenAdress,
    modifyAdress
}