import axios from "axios";
import router from '@/router'
import store from "@/store/index"
import qs from "qs"
import Vue from "vue";


//创建axios实例
var service = axios.create({ 
    // baseURL: "http://192.168.1.113:8088",
    baseURL: "https://api.jyc.huanuowei.cn",
    timeout: 5000,
    //   withCredentials: true,
    headers: {
        "content-type": "application/x-www-form-urlencoded",
        // "Access-Control-Allow-Origin":'*'
    },
    
});

let tipMsg = true

// axios请求拦截器
service.interceptors.request.use(
    config => {
        if(config.data){
            if(!config.data.store_id){
            //   config.data.store_id =  store.state.store_id
            } 
          }
        if (store.state) {
            config.headers.Authorization = store.state.token
        }

        config.data = qs.stringify(config.data)
        return config

    }, function (error) {
        return Promise.reject(error)
    } 
)
// axios响应
service.interceptors.response.use(
    (response) => { 
    if (response.data.code == 1) {
        Vue.prototype.$erMsg(response.data.msg)
        return false
    } else if (response.data.code == 401) { 
        if (tipMsg) { 
            tipMsg = false
        }

        localStorage.removeItem("jyc-web-state")
        store.commit("resetState")
        router.push("/login")
        return false
    } else if (response.data.code == 305) {
        location.reload()
    } else {
        return response.data
    }
}, (error) => { 
    // 对响应错误做点什么 
	return Promise.reject(error);
})

export default {
    service,
    //get请求
    get(url, data) {
        return service({
            url: url,
            method: "get",
            query: data,
        })
    },

    //post请求
    post(url, data) {
        return service({
            url: url,
            method: "post",
            data: data,
        })
    },

    //post请求
    delete(url, data) {
        return service({
            url: url,
            method: "delete",
            query: data,
        })
    },
};