<template>
  <div id="app">
    <!-- <homeNav></homeNav> -->
    <router-view/>
  </div>
</template>

<script>
// import homeNav from "@/components/headerNav.vue"
 export default {
  created(){
    let reslogin = localStorage.getItem('jyc-web-state')
    this.$store.commit("UpDateState",JSON.parse(reslogin))  
  },
  // components:{
  //   homeNav
  // }
 }
</script>

<style>
#app{
  background: #f2f2f2;
  min-height: 100vh; 
}
</style>
