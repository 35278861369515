import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import api from "@/common/api"

Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$api = api

// 成功的提示
Vue.prototype.$suMsg = function (message) {
  this.$message({
    message,
    type: "success",
    duration: 800
  })
}

// 失败的提示
Vue.prototype.$erMsg = function (message) {
  this.$message({
    message,
    type: "error",
    duration: 1300
  })
}

// 警告的提示
Vue.prototype.$warMsg = function (message) {
  this.$message({
    message,
    type: "warning",
    duration: 800
  })
}

// 普通的提示
Vue.prototype.$infoMsg = function (message) {
  this.$message({
    message,
    type: "info",
    duration: 800
  })
}
Vue.prototype.$affMsg = function (title, su, er) {
  this.$confirm(title, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    su()
  }).catch(() => {
    if (er) {
      er()
    }
  });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
