import http from "@/common/http"; //axios实例化后引入取名http

const getLogin = async (data)=>{
    let list = await http.post(`/user/app.login`,{ 
        ...data
    })
    return list
}

export {  
    getLogin
}