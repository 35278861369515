import http from "@/common/http"; //axios实例化后引入取名http

// 加入购物车
const joinShopcar = async (data)=>{
    let list = await http.post(`/cart/app.add/${data.id}`,{ 
        ...data
    })
    return list
}

// 购物车列表
const shopCarList = async (data)=>{
    let list = await http.post(`/cart/app.list`,{ 
        ...data
    })
    return list
}

// 增加购物车数量
const addShopNum = async (data)=>{
    let list = await http.post(`/cart/app.reset/${data.id}`,{ 
        ...data
    })
    return list
}

// 删除购物车
const reduceCart = async (data) => {
    let list = await http.delete(`/cart/app.del/${data.id}`,{ 
        ...data
    })
    return list
}

export {  
    joinShopcar,
    shopCarList,
    addShopNum,
    reduceCart,
} 