<template>
  <div class="home-style"> 
    <!-- 头部导航 --> 
    <headerNav v-if="$route.path!='/find'"></headerNav> 
    <div class="main-style">
      <router-view></router-view>
    </div>
    <footer class="footer-style">
      <div class="compone-info" style="width: 1200px;margin: 10px auto;">
        <div style="font-size: 14px;">
          <div style="color: #666;margin: 10px 0;">公司名称</div>
          <div>景阳诚汽车零部件(上海)股份有限公司</div>
        </div> 
        <div style="font-size: 14px;">
          <div style="color: #666;margin: 10px 0;">联系人</div>
          <div>郭恒强</div>
        </div>
        <div style="font-size: 14px;">
          <div style="color: #666;margin: 10px 0;">联系方式</div>
          <div>13598725560</div>
        </div>
        <div style="font-size: 14px;">
          <div style="color: #666;margin: 10px 0;">联系地址</div>
          <div>上海市嘉定区曹安公路4588号1幢2层A区</div>
        </div>
        <div style="font-size: 14px;display: flex;margin-right: 20px;">
           <div style="text-align: center;">
            <img width="90" height="90" src="@/assets/index/gzherweima.png" alt="">
            <div>公众号</div>
           </div>
           <div style="text-align: center;">
            <img width="90" height="90" src="@/assets/index/gzherweima.png" alt="">
            <div>小程序</div>
           </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script> 
// 头部导航 
import headerNav from "@/components/headerNav.vue" 
export default {
  data() {
    return { 
    };
  },
  methods: { 
  },
  async created() {
    // console.log("this.$",this.$route)
  },
  components: {   
    headerNav,
  },
};
</script>

<style lang="scss" scoped>
.home-style { 
  .main-style{
    min-height: calc(100vh - 300px);
  }
  .footer-style{
    height: 140px;
    background-color: #fff;
    .compone-info{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>