import http from "@/common/http"; //axios实例化后引入取名http

const getRecommend = async (data)=>{
    let list = await http.post("/goods/app.recommend",{ 
        ...data
    })
    return list
}

const getBanner = async (data) => {
    let list = await http.get('/banner/app.list',{
        ...data
    })
    return list
}

// 获取详情页信息
const getGoodsInfo = async (data) => {
    let list = await http.get(`/goods/app.details/${data.id}`,{ 
    })
    return list
}

// 搜索
const searchGoods = async (data) => {
    let list = await http.post(`/goods/app.list`,{ 
        ...data
    })
    return list 
}

// 搜索历史记录
const getSearchHis = async (data) => {
    let list = await http.get(`/record/app.list`,{
        ...data
    })
    return list
}

const getUserInfo = async () => {
    let list = await http.get(`/user/app.info`,{

    })
    return list
}

export {  
    getRecommend,
    getBanner,
    getGoodsInfo,
    searchGoods,
    getSearchHis,
    getUserInfo,
}